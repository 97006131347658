import { useState } from 'react'
import {
  useCreateFaultFinderMutation,
  useGetImagesQuery
} from '../../../../api/inspectionOps'
import {
  useGetMLModelByIdQuery,
  useLazyGetMLModelVersionsQuery,
} from '../../../../api/geoAiConfig'
import TopXCloseButton from '../../../shared/TopXCloseButton'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  ImageSearch,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

const ML_MODEL_ID = process.env.REACT_APP_ML_MODEL_ID
if (!ML_MODEL_ID) throw new Error('Environment Variable REACT_APP_ML_MODEL_ID is required.')

const ML_MODEL_VERSION_ID = process.env.REACT_APP_ML_MODEL_VERSION_ID
if (!ML_MODEL_VERSION_ID) throw new Error('Environment Variable REACT_APP_ML_MODEL_VERSION_ID is required.')

const styles = theme => ({
  content: {
    padding: theme.spacing(8),
    display: 'flex',

  },
  icon: {
    width:  theme.spacing(8),
    height: theme.spacing(8),
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
})

function AISimpleDialog({ classes, onClose, inspectionId }) {

  const { data, error, isLoading } = useGetImagesQuery({ inspection_id: inspectionId }, {
    skip: !inspectionId
  })
  const inspImages = data?.data?.images || []
  const [ createFaultFinder ] = useCreateFaultFinderMutation()

  const { 
    data: mlModelData,
    error: mlModelError,
    isLoading: mlModelIsLoading 
  } = useGetMLModelByIdQuery(ML_MODEL_ID)
  const [ getMLModelVersions ] = useLazyGetMLModelVersionsQuery()

  const [ isStarting, setIsStarting ] = useState(false)

  const TIME_PER_IMAGE = 3 //in seconds
  function calculateTotalTime(images) {
    // Calculate the total time in minutes
    let totalTimeSeconds = images * TIME_PER_IMAGE
    let totalTimeMinutes = totalTimeSeconds / 60
  
    // Round the total time to the nearest 5 minutes
    totalTimeMinutes = Math.ceil(totalTimeMinutes / 5) * 5
  
    return totalTimeMinutes
  }

  async function getMlModelVersion(mlModelId) {
    const mlModelVersions = await getMLModelVersions(mlModelId).unwrap()
    const mlModelVersion = mlModelVersions.data.ml_model_version
      .find(version => version._id === ML_MODEL_VERSION_ID)
    return mlModelVersion
  }

  async function start() {
    setIsStarting(true)
    const selectedMlModel = {
      ...mlModelData.data.ml_model,
    }

    let mlModelVersion
    try {
      mlModelVersion = await getMlModelVersion(selectedMlModel._id)
    } catch {
      console.error('failed to get ml model version')
      toast.error('Failed to get model version')
      return
    }

    const selectedMlmVersion = {
      ...mlModelVersion,
    }
    const metadata_map = {}

    inspImages.forEach(image => {
      metadata_map[image.media_id] = {
        inspection_image_id: image._id
      }
    })

    const msg = {
      metadata: {
        inspection_id: inspectionId,
        algorithm:     `${selectedMlModel.name} ${selectedMlmVersion.semver}`,
        ml_model_version_id: selectedMlmVersion._id
      },
      metadata_map,
      labels_media_id:  selectedMlModel.labels_media_id,
      weights_media_id: selectedMlmVersion.weights_media_id,
      configs_media_id: selectedMlmVersion.configs_media_id,
      images_media_id:  inspImages.map(image => image.media_id)
    }

    createFaultFinder(msg)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('create failed', fulfilled)
        toast.error('Failed to send request')
      } else {
        console.log('create success', fulfilled)
        toast.success('Request sent successfully')
        onClose()
      }
    })
    .catch(rejected => {
      console.error('create rejected', rejected)
      toast.error('Error sending request')
    })
    .finally(() => {
      setIsStarting(false)
    })
  }

  if (error || mlModelError) {
    return null
  }
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>Apply AI Algorithm</DialogTitle>
      <DialogContent className={classes.content}>
        <ImageSearch className={classes.icon} />
        <div>
          <Typography variant='h2'>FaultFinder&trade;</Typography>
          { isLoading ? <>
            <CircularProgress />
            <Typography variant='body1'>Loading inspection images</Typography>
          </> :
            <Typography variant='body1' gutterBottom>
              Estimated completion time for {inspImages.length} images is {calculateTotalTime(inspImages.length)}     
            </Typography>
          }
          <br />
          <br />
          { mlModelIsLoading ? <>
            <CircularProgress />
            <Typography variant='body1'>Loading machine learning model</Typography>
          </> : 
            <Button variant='contained' color='primary'
              disabled={isStarting}
              onClick={start}>
              Start
            </Button>
          }
          
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(AISimpleDialog)
