import { useState, useEffect, useRef } from 'react'
import {
  useGetMediaByIdQuery
} from '../../../../api/media'
import LabelledImage from '../../../shared/LabelledImage'
import {
  Avatar,
  Box,
  Chip,
  Card,
  // CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  // Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    width: '260px',
    cursor: 'pointer',
    position: 'relative',
    zIndex: '2',
    top: '0px'
  },
  content: {
    padding: theme.spacing(0, 2, 2, 2),
  },
})

function InspectionImageCard({ classes, image, setExpandedImage }) {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setWidth(ref.current.clientWidth)
    setHeight(ref.current.clientHeight)
  }, [setWidth, setHeight])

  const { data } = useGetMediaByIdQuery(image?.media_id, {
    skip: !(image && image.media_id)
  })
  if (!image)
    return

  const media = data?.media
  const imgW = media?.exif?.image_width
  const imgH = media?.exif?.image_height
  const labels = image.defects.map(d => ({
    shape: d.instance.shape,
    x: d.instance.x,
    y: d.instance.y,
    w: d.instance.w,
    h: d.instance.h,
    polygon: d.instance.polygon,
    imgW: imgW,
    imgH: imgH,
  }))
  // TODO: Click based on region of the image to different defect.
  return (
    <Grid item xxl={1} style={{marginTop: '16px'}}>
      <Box style={{position: 'relative'}}>
        { labels.map((e, i) => i > 0 && i <= 3 && <Card key={i} style={{position: 'absolute', height: height, width: width, zIndex: `-${i}`, top: `${4*i}px`, left: `${4*i}px`}}> </Card>) }
        <Card ref={ref} className={classes.card} onClick={() => setExpandedImage(image._id)}>
          <CardMedia>
            <LabelledImage
              media_id={image.media_id}
              size='preview'
              labels={labels}
              scaleWidth={260}
            />
          </CardMedia>
          <CardContent className={classes.content}>
            <Typography>{image.filename}</Typography>
            {Object.keys(image.surface_types).map(st => (
              <Chip key={st}
                avatar={<Avatar>{image.surface_types[st]}</Avatar>}
                label={st} />
            ))}
          </CardContent>
          {/*<Tooltip title={defect.severity ? `${defect.severity} severity` : ''}>
            <div className={classes.severity} />
          </Tooltip>*/}
        </Card>
      </Box>
    </Grid>
  )
}

export default withStyles(styles)(InspectionImageCard)
