import { full_dateEpoch, pretty } from '../../../../utils'
import { allRoles } from '../../../../utils/enums'
import { useGetMediaByIdQuery, useMediaURL } from '../../../../api/media'
import Company from '../../../shared/text/Company'
import User    from '../../../shared/text/User'
import Image   from '../../../shared/Image'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
  Close,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import TopXCloseButton from '../../../shared/TopXCloseButton'

const styles = theme => ({
  img: {
    width: '100%',
    borderRadius: theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(1)
  },
  gridContainer: {
    padding: theme.spacing(2)
  }
})

function ImageDialog({ classes, mediaId, open, onClose, onPrev, onNext, onAnnotate }) {
  const { data, error, isLoading } = useGetMediaByIdQuery(mediaId, {
    skip: !mediaId
  })
  if (!isLoading) {
    if (error)
      console.log('ImageDialog error', error)
    else if (!data.success)
      console.log('ImageDialog fail', data)
  }
  const media = data?.media
  const href = useMediaURL(media?._id, 'original')

  function renderPermission(p) {
    if (!p) return '-'

    let s = ''
    if (p.accessibility === 'limited')
      s += 'Limited to roles in '
    if (p.facilities) {
      s += 'Facilities 4.0 (' + p.facilities.map(r => allRoles[r]?.label).join(', ') + ')'
    }
    return s
  }
  const exif = media?.exif
  const location = exif?.location
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='xl'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>
        <div className={classes.title}>
          <Typography variant='h6'>
            { media?.original_name || 'Unknown Image' }
          </Typography>
          <Typography variant='body2'>
            { exif?.date_taken ? <>{new Date(exif?.date_taken).toString()} &nbsp; |</> : '' } &nbsp;
            Download <a href={href} target='_blank' rel='noreferrer'>
              Original
            </a> {/* TODO: upgrade Media Service to provide size ([ size ])*/}
          </Typography>
          <IconButton onClick={onClose} color='primary'>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        { media ?
          <Image media_id={media._id} size='fullscreen' alt={media.original_name} className={classes.img} />
        :
          <CircularProgress />
        }
        <Grid container className={classes.gridContainer} spacing={3}>
          <Grid item container md={6} spacing={2}>
            <Grid item xs={12}><b>Media Metadata</b></Grid>
            <Grid item xs={5}>Original Filename</Grid>
            <Grid item xs={7}>{media?.original_name || '-'}</Grid>
            <Grid item xs={5}>Owner</Grid>
            <Grid item xs={7}>{media?.owner ? <User id={media.owner} name /> : '-'}</Grid>
            <Grid item xs={5}>Company</Grid>
            <Grid item xs={7}>{media?.company_id ? <Company id={media.company_id} name /> : '-'}</Grid>
            <Grid item xs={5}>Created on</Grid>
            <Grid item xs={7}>{media?.created_on ? full_dateEpoch(media.created_on) : '-'}</Grid>
            <Grid item xs={5}>Uploaded on</Grid>
            <Grid item xs={7}>{media?.uploaded_on ? full_dateEpoch(media.uploaded_on) : '-'}</Grid>
            <Grid item xs={5}>Permission</Grid>
            <Grid item xs={7}>{renderPermission(media?.permissions)}</Grid>
            <Grid item xs={5}>Status</Grid>
            <Grid item xs={7}>{media?.status ? pretty(media.status) : '-'}</Grid>
            <Grid item xs={5}>Project</Grid>
            <Grid item xs={7}>{media?.project_id || '-'}</Grid>
            <Grid item xs={5}>Deployment</Grid>
            <Grid item xs={7}>{media?.deployment_id || '-'}</Grid>
            <Grid item xs={5}>Flight Plan</Grid>
            <Grid item xs={7}>{media?.flight_plan_id || '-'}</Grid>
            <Grid item xs={5}>Flight</Grid>
            <Grid item xs={7}>{media?.flight_id || '-'}</Grid>
          </Grid>
          <Grid item container md={6}>
            <Grid item xs={12}><b>Image EXIF data</b></Grid>
            <Grid item xs={5}>Date Taken</Grid>
            <Grid item xs={7}>{exif?.date_taken ? full_dateEpoch(exif.date_taken) : '-'}</Grid>
            <Grid item xs={5}>Image Size</Grid>
            <Grid item xs={7}>{exif?.image_width} w x {exif?.image_height} h (pixels)</Grid>
            <Grid item xs={5}>Camera</Grid>
            <Grid item xs={7}>{exif?.camera_make} {exif?.camera_model}</Grid>
            <Grid item xs={5}>Latitude</Grid>
            <Grid item xs={7}>{location?.latitude || '-'}</Grid>
            <Grid item xs={5}>Longitude</Grid>
            <Grid item xs={7}>{location?.longitude || '-'}</Grid>
            <Grid item xs={5}>Altitude (Above Ground)</Grid>
            <Grid item xs={7}>{location?.relative_altitude || '-'} m</Grid>
            <Grid item xs={5}>Altitude (Mean Sea Level)</Grid>
            <Grid item xs={7}>{location?.absolute_altitude || '-'} m</Grid>
            <Grid item xs={5}>Flight Attitude</Grid>
            <Grid item xs={7}>
              Pitch {exif?.flight_pitch_degree || '-'}&deg;,
              Roll {exif?.flight_roll_degree || '-'}&deg;,
              Yaw {exif?.flight_yaw_degree || '-'}&deg;
            </Grid>
            <Grid item xs={5}>Gimbal Attitude</Grid>
            <Grid item xs={7}>
              Pitch {exif?.gimbal_pitch_degree || '-'}&deg;,
              Roll {exif?.gimbal_roll_degree || '-'}&deg;,
              Yaw {exif?.gimbal_yaw_degree || '-'}&deg;
            </Grid>
            <Grid item xs={5}>Focal Length</Grid>
            <Grid item xs={7}>{exif?.focal_length || '-'}</Grid>
            <Grid item xs={5}>Focal length (35mm equivalent)</Grid>
            <Grid item xs={7}>{exif?.focal_length35mm || '-'}</Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ width: '100%', justifyContent: 'space-between' }}>
        <Button
          onClick={onPrev}
          variant='contained'
          startIcon={<ChevronLeft />}>
          Prev
        </Button>
        <Button
          onClick={onAnnotate}
          variant='contained'>
          Annotate
        </Button>
        <Button
          onClick={onNext}
          variant='contained'
          endIcon={<ChevronRight />}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ImageDialog)
