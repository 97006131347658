import { useRef, useEffect, useState, useCallback } from 'react'
import { useAccessToken } from '../../../../../api/accounts'
import { getMediaURL, useGetMediaByIdQuery } from '../../../../../api/media'
import TopXCloseButton from '../../../../shared/TopXCloseButton'
import DialogActionButtons from './components/DialogActionButtons'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  fileUpload: {
    width: theme.spacing(27),
    overflow: 'hidden'
  },
  content: {
    // height: theme.breakpoints.values.sm,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  }
})

function ElevationEditQuadrilateralModal({
  elevation,
  classes,
  open,
  onClose,
  onNext,
  onBack,
  name,
  setQuadrilateral,
  topAGL,
  setTopAGL,
  bottomAGL,
  setBottomAGL,
}) {
  const [ errorMessage, setErrorMessage ] = useState('')

  const validAGL = (agl) => {
    return /^-?[0-9]+(\.[0-9]+)?$/.test(agl)
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper}} open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        Step 2: Confirm the boundary of the Elevation
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant='h4'>Elevation: {name}</Typography>
        <Grid container alignItems='center' spacing={3}>
          <Grid item xs={5}>
            <Typography variant='body1'>
                  Drag the corner points to change the boundary of the Elevation. <br/>
                  Click “Save” if you’re satisfied. <br/><br/>
                  Note: Detection points shown on this elevation will be adjusted
            </Typography>
            <TextField 
              style={{marginTop: '16px'}}
              variant={'outlined'}
              label='Top Altitude (AMSL)'
              value={topAGL}
              onChange={(e) => {
                setErrorMessage('')
                setTopAGL(e.target.value)
              }}/><br/>
            <TextField 
              style={{marginTop: '16px'}}
              variant={'outlined'}
              label='Bottom Altitude (AMSL)'
              value={bottomAGL}
              onChange={(e) => {
                setErrorMessage('')
                setBottomAGL(e.target.value)
              }}/>
            {
              errorMessage &&
              <Typography variant='body1' style={{color: 'red'}}>
                  {errorMessage}
              </Typography>
            }
          </Grid>
          <Grid item xs={7}>
            <Box
              flexGrow='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <QuadrilateralCanvas elevation={elevation} setQuadrilateral={setQuadrilateral}/>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButtons
          onNext={() => {
            if (!validAGL(topAGL)) {
              setErrorMessage('Invalid top altitude')
              return
            }
            if (!validAGL(bottomAGL)) {
              setErrorMessage('Invalid bottom altitude')
              return
            }
            if (Number(topAGL) < Number(bottomAGL)) {
              setErrorMessage('Top altitude must be above bottom altitude')
              return
            }
            onNext()
          }}
          onCancel={onBack}
          disableNext={topAGL === '' || bottomAGL === ''}
          nextText={'Save'} />
      </DialogActions>
    </Dialog>
  )
}

function QuadrilateralCanvas({ elevation, setQuadrilateral}) {
  const access_token = useAccessToken()
  const canvasRef = useRef(null)
  const backgroundRef = useRef(null)
  const [ picked, setPicked ] = useState(null)
  const [ temporaryCoord, setTemporaryCoord ] = useState({})
  const { data } = useGetMediaByIdQuery(elevation?.images[0]?.media_id, { skip: !elevation?.images[0]?.media_id })

  const verticesRef = useRef([])

  const handleMouseDown = useCallback((event) => {
    event.preventDefault()
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const dotSize = 5

    const clickX = (event.clientX - rect.left) / rect.width
    const clickY = (event.clientY - rect.top)  / rect.height

    function pointHitTest(x, y, point) {
      return (x >= point[0] - .5 * dotSize / rect.width &&
        x <= point[0] + .5 * dotSize / rect.width &&
        y >= point[1] - .5 * dotSize / rect.height &&
        y <= point[1] + .5 * dotSize / rect.height)
    }

    for (let i = 0; i < elevation.images[0].label.length; i++) {
      const point = temporaryCoord[i] ? temporaryCoord[i] : elevation.images[0].label[i]
      if (pointHitTest(clickX, clickY, point)) {
        setPicked(i)
      }
    }
  }, [elevation, temporaryCoord])

  const handleMouseMove = useCallback((event) => {
    if (picked === null) return

    event.preventDefault()
    const canvas = canvasRef.current
    const rect   = canvas.getBoundingClientRect()
    const clickX = (event.clientX - rect.left) / rect.width
    const clickY = (event.clientY - rect.top)  / rect.height
    
    temporaryCoord[picked] = [clickX, clickY]
    setTemporaryCoord({ ...temporaryCoord })
    
    const quad = []
    for (let i = 0; i < elevation.images[0].label.length; i++) {
      const v = temporaryCoord[i] ? temporaryCoord[i] : elevation.images[0].label[i]
      quad.push({
        x: v[0] * rect.width, 
        y: v[1] * rect.height, 
        height: 0,
        imgWidth: rect.width,
        imgHeight: rect.height
      })
    }
    setQuadrilateral(quad)
  }, [picked, temporaryCoord, elevation, setQuadrilateral])

  const handleMouseUp = useCallback((event) => {
    event.preventDefault()
    setPicked(null)
  }, [])

  const handleMouseOut = useCallback((event) => {
    event.preventDefault()
    setPicked(null)
  }, [])

  const draw = useCallback(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const rect = canvas.getBoundingClientRect()

    context.clearRect(0, 0, rect.width, rect.height)
    context.drawImage(backgroundRef.current, 0, 0, rect.width, rect.height)

    const vertices = elevation?.images?.[0]?.label
    
    if (!vertices || vertices.length === 0) return

    const color = 'cyan'
    const dotSize = 5
    const v0 = temporaryCoord[0] ? temporaryCoord[0] : vertices[0]
    const vlast = temporaryCoord[vertices.length - 1] ? temporaryCoord[vertices.length - 1] : vertices[vertices.length - 1]

    context.beginPath()
    context.strokeStyle = color
    context.fillStyle = color
    context.fillRect(
      v0[0] * rect.width - .5 * dotSize,
      v0[1] * rect.height - .5 * dotSize,
      dotSize, dotSize
    )
    context.stroke()
    
    context.beginPath()
    context.lineWidth = 1
    context.moveTo(
      vlast[0] * rect.width,
      vlast[1] * rect.height
    )
    context.lineTo(v0[0] * rect.width, v0[1] * rect.height)
    context.stroke()

    context.fillStyle = 'rgba(0, 255, 255, 0.5)'
    context.beginPath()
    context.moveTo(v0[0] * rect.width, v0[1] * rect.height)
    for (let i = 1; i < vertices.length; i++) {
      const v = temporaryCoord[i] ? temporaryCoord[i] : vertices[i]
      
      context.fillRect(
        v[0] * rect.width - .5 * dotSize,
        v[1] * rect.height - .5 * dotSize,
        dotSize, dotSize
      )
      context.stroke()
      context.lineTo(v[0] * rect.width, v[1] * rect.height)
    }
    context.closePath()
    context.fill()
  }, [elevation, temporaryCoord])

  useEffect(() => {
    if (!elevation) return

    const background = new Image()
    verticesRef.current = []
    const ctx = canvasRef.current.getContext('2d')

    const handleResize = (width, height) => {
      ctx.canvas.width = canvasRef.current.parentElement.clientWidth
      ctx.canvas.height = ctx.canvas.width * height / width
    }

    background.onload = () => {
      const media = data?.media
      if (media)
        handleResize(media.exif.image_width, media.exif.image_height)
      draw()
    }

    background.src = getMediaURL(elevation.images[0].media_id, 'fullscreen', access_token)
    backgroundRef.current = background

  }, [data, elevation, draw, access_token])

  useEffect(() => {
    if (!elevation) return
    const rect = canvasRef.current.getBoundingClientRect()

    const quad = []
    for (let i = 0; i < elevation.images[0].label.length; i++) {
      quad.push({
        x: elevation.images[0].label[i][0] * rect.width, 
        y: elevation.images[0].label[i][1] * rect.height, 
        height: 0,
        imgWidth: rect.width,
        imgHeight: rect.height
      })
    }
    setQuadrilateral(quad)
  }, [elevation, setQuadrilateral])

  useEffect(() => {
    draw()
  }, [picked, temporaryCoord, draw])

  useEffect(() => {
    const canvas = canvasRef.current

    canvas.addEventListener('mousedown', handleMouseDown)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('mouseup', handleMouseUp)
    canvas.addEventListener('mouseout', handleMouseOut)

    return () => {
      canvas?.removeEventListener('mousedown', handleMouseDown)
      canvas?.removeEventListener('mousemove', handleMouseMove)
      canvas?.removeEventListener('mouseup', handleMouseUp)
      canvas?.removeEventListener('mouseout', handleMouseOut)
    }
  }, [handleMouseDown, handleMouseMove, handleMouseUp, handleMouseOut])

  return (
    <Box style={{position: 'relative', width: '100%', height: '100%', display: 'flex'}} overflow='auto'>
      <canvas ref={canvasRef} style={{border: '1px solid black', cursor: 'crosshair', maxWidth: '100%'}}></canvas>
    </Box>
  )
}

export default withStyles(styles)(ElevationEditQuadrilateralModal)