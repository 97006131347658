import { useState } from 'react'
import { toast } from 'react-toastify'
import {
  allInspectionStatuses,
} from '../../../utils/enums'
import {
  useGetDefectsQuery,
  useGetDocumentsQuery,
  useGetElevationsQuery,
  useGetFacilityByIdQuery,
  useGetImagesQuery,
  useGetInspectionByIdQuery,
} from '../../../api/inspectionOps'
import { useMediaURL }  from '../../../api/media'

import {
  InspectionStatusIcon,
} from '../../shared/Icons'
import User from '../../shared/text/User'
import Image            from '../../shared/Image'
import ElevationImage   from '../../shared/ElevationImage'
import MetaDialog       from '../../shared/MetaDialog'
import NavBar           from '../../shared/NavBar'

import FacilityPreview  from './Components/FacilityPreview'
import StatusDialog     from './Components/StatusDialog'
// import TopXCloseButton from '../../shared/TopXCloseButton'

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  // ImageListItemBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import {
  Add,
  AssignmentTurnedIn,
  BarChart,
  BurstMode,
  DateRange,
  Description,
  Edit,
  EventAvailable,
  ErrorOutline,
  FlashOn,
  GetApp,
  Group,
  Image as ImageIcon,
  Notes,
  OpenInBrowser as Launch,
  Person,
  PersonAddDisabled,
} from '@material-ui/icons'

const styles = theme => ({
  root: {
    padding: theme.spacing(1, 2),
    display: 'flex',
  },
  title: {
    padding: theme.spacing(1, 2, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftPanel: {
    minWidth: theme.spacing(35),
  },
  middlePanel: {

  },
  rightPanel: {
    minWidth: theme.spacing(35),
  },
  topDivider: {
    height: '1px',
    margin: theme.spacing(0, 0, 1),
  },
  divider: {
    height: '1px',
    margin: theme.spacing(1, 0),
  },
  cardWrapper: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  editButton: {
    marginLeft: theme.spacing(1)
  },
  actionButtons: {
    marginRight: theme.spacing(1),
  }
})

function InspectionSummary({ classes, history, match }) {
  const inspectionId = match.params.inspectionId

  const {
    data: inspectionData,
    isLoading: inspectionIsLoading
  } = useGetInspectionByIdQuery(inspectionId, {
    skip: !inspectionId
  })
  const inspection = inspectionData?.data?.inspection || {}

  const facilityId = inspection.facilities?.length > 0 ? inspection.facilities[0] : ''
  const { data: facilityData } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId
  })
  const facility = facilityData?.data?.facility || {}

  const { data: documentData } = useGetDocumentsQuery({ inspectionId })
  const documents = documentData?.data?.documents || []

  const { data: imagesData } = useGetImagesQuery({
    inspection_ids: [inspectionId],
    sort:         'exif_created_time_desc',
  }, {skip: !inspectionId})
  const images = imagesData?.data?.images || []

  const { data: defectsData } = useGetDefectsQuery({
    inspection_ids: [inspectionId]
  }, {
    skip: !inspectionId
  })
  const detections = defectsData?.data?.defects || []

  const [ metaData, setMetaData ] = useState({})
  const [ metaOpen, setMetaOpen ] = useState(false)
  const [ statusOpen, setStatusOpen ] = useState(false)
  const [ showEle, setShowEle ] = useState(null)

  function handleEdit() {
    setMetaData(inspection)
    setMetaOpen(true)
  }

  function handleStatus() {
    setMetaData(inspection)
    setStatusOpen(true)
  }

  const latestDocument = documents.toSorted((a, b) => b.created_on - a.created_on)[0]
  const latestDocumentHref = useMediaURL(latestDocument?.original_file_media_id, 'original')

  function handleDownload() {
    toast.success('Download started')
    window.location.href = latestDocumentHref
  }

  const {
    data: elevationData,
    error: elevationError
  } = useGetElevationsQuery({
    facility_id: facilityId
  }, {
    skip: !facilityId
  })

  if (elevationError)
    console.error(elevationError)
  
  return <>
    <NavBar
      midSubtitleOverline='inspection'
      midSubtitle={inspection.name || 'Untitled Inspection'} 
      subtitleOverline='facility'
      subtitle={facility.name}
      subtitleLink={`/facilities/${facility._id}`}/>
    <Grid container className={classes.root}>
      <Grid item sm={4} className={classes.leftPanel}>
        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader
              avatar={<Avatar><AssignmentTurnedIn /></Avatar>}
              title={inspection.name || 'Untitled Inspection'}
              action={ inspection._id &&
                <>
                <Button 
                  variant='outlined' 
                  color='primary' 
                  startIcon={<Edit/>}
                  onClick={handleStatus}>
                    Status
                </Button>
                <Button 
                  className={classes.editButton}
                  variant='outlined' 
                  color='primary' 
                  startIcon={<Edit/>}
                  onClick={handleEdit}>
                    Edit
                </Button>
                </>
              }
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <CardContent>
              <FacilityPreview facility={facility} inspection={inspection} />
              <List>
                <ListItem>
                  <ListItemIcon>
                    <InspectionStatusIcon type={inspection.status} />
                  </ListItemIcon>
                  <ListItemText primary={`Status: ${allInspectionStatuses[inspection.status]?.label || 'Unknown inspection status'}`} />
                </ListItem>
                <ListItem>
                  <ListItemIcon><DateRange /></ListItemIcon>
                  <ListItemText primary={`Start Date: ${new Date(inspection.start_date).toLocaleDateString() || 'No start date'}`} />
                </ListItem>
                <ListItem>
                  <ListItemIcon><EventAvailable /></ListItemIcon>
                  <ListItemText primary={`End Date: ${new Date(inspection.end_date).toLocaleDateString() || 'No end date'}`} />
                </ListItem>
                { inspection.notes &&
                  <ListItem>
                    <ListItemIcon><Notes/></ListItemIcon>
                    <ListItemText primary={`Notes: ${inspection.notes}`} />
                  </ListItem>
                }
              </List>
            </CardContent>
          </Card>
        </div>
        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader 
              avatar={<Avatar><BurstMode /></Avatar>}
              title={'Elevations and Detections'}
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <Dialog open={showEle!==null} onClose={() => {setShowEle(null)}} fullWidth maxWidth='md'>
                <DialogContent>
                  { showEle && <ElevationImage elevation={showEle} navigateable={true} showDefects={true} inspection_id={inspectionId}/> }
                </DialogContent>
            </Dialog>
            { elevationData && elevationData.data.elevations.map((elevation, index) =>
              elevation.images[0]?.media_id && images.map(i => i.facility_elevations[0]).indexOf(elevation._id) > -1 &&
              <Box key={index} style={{position: 'relative', cursor: 'pointer'}} onClick={() => {setShowEle(elevation)}}>
                <ElevationImage elevation={elevation} showDefects={true} inspection_id={inspectionId}/>
                <Box style={{position: 'absolute', zIndex: '2', bottom: '4px', width: '100%', color: '#ffffff', backgroundColor: 'rgba(0,0,0,0.5)', padding: '4px 0px 4px 12px'}}>
                  <Typography variant='h6'>{elevation.name}</Typography>
                </Box>
              </Box>
            )}
          </Card>
        </div>
      </Grid>
      <Grid item sm={4} className={classes.middlePanel}>
        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader
              avatar={<Avatar><BarChart /></Avatar>}
              title={'Inspection Details'}
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <CardContent>
              <List>
                { inspection.status &&
                  <ListItem>
                    <ListItemIcon>
                      <InspectionStatusIcon type={inspection.status} />
                    </ListItemIcon>
                    <ListItemText
                      primary={allInspectionStatuses[inspection.status]?.label || 'Unknown inspection status'}
                      secondary={new Date().toLocaleString()} />
                  </ListItem>
                }
                <ListItem>
                  <ListItemIcon><BarChart /></ListItemIcon>
                  <ListItemText
                    primary={<>Inspection last modified by <User id={inspection.last_modified_by} name /></>}
                    secondary={new Date(inspection.last_modified_on).toLocaleString()} />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText
                    primary={<>Inspection created by <User id={inspection.created_by} name /></>}
                    secondary={new Date(inspection.created_on).toLocaleString()} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </div>
        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader 
              avatar={<Avatar><Group /></Avatar>}
              title={'Personnel'}
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <CardContent>
              <List>
                { inspection.personnel ? <>
                  <ListItem>
                    <Typography variant='h6'>Personnel</Typography>
                  </ListItem>
                  { inspection.personnel.map((p, i) =>
                    <ListItem key={i}>
                      <ListItemIcon><Person /></ListItemIcon>
                      <ListItemText primary={p.name} secondary={p.email} />
                    </ListItem>
                  ) }
                  </> : <>
                    <ListItem>
                      <ListItemIcon><PersonAddDisabled /></ListItemIcon>
                      <ListItemText primary='No personnel assigned' />
                    </ListItem>
                  </> 
                }
              </List>
            </CardContent>
          </Card>
        </div>
      </Grid>
      <Grid item sm={4} className={classes.rightPanel}>
        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader 
                avatar={<Avatar><ImageIcon /></Avatar>}
                action={
                  <IconButton
                    style={{ padding: 0 }}
                    disabled={inspectionIsLoading}
                    href={`/inspection/${inspection._id}/gallery`}
                  >
                    <Launch />
                  </IconButton>
                }
                title={'Latest Images'}
                titleTypographyProps={{ variant: 'h5' }}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.root}>
                  { images.length === 0 ?
                    <ListItem>
                      <ListItemIcon><ErrorOutline /></ListItemIcon>
                      <ListItemText primary='No images found' />
                    </ListItem>
                  :
                    <ImageList gap={1} cols={3} className={classes.imageList}>
                      {images.map((image, i) => (
                        i < 6 &&
                        <ImageListItem key={i}>
                          <Image media_id={image.media_id} size='preview' alt={image.filename} />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  }
                </div>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button size='small' variant='outlined' color='primary'
                      disabled={inspectionIsLoading}
                      href={`/inspection/${inspectionId}/upload`}>
                      Upload
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size='small' variant='outlined' color='primary'
                      disabled={inspectionIsLoading}
                      href={`/inspection/${inspectionId}/detections`}>
                      Detections
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
          </Card>
        </div>
        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader 
                avatar={<Avatar><FlashOn /></Avatar>}
                action={
                  <IconButton style={{ padding: 0 }}
                    disabled={inspectionIsLoading}
                    href={`/inspection/${inspection._id}/detections`}>
                    <Launch />
                  </IconButton>
                }
                title={'Latest Detections'}
                titleTypographyProps={{ variant: 'h5' }}
                className={classes.cardHeader}
              />
            <CardContent>
              <div className={classes.root}>
                { detections.length === 0 ?
                  <ListItem>
                    <ListItemIcon><ErrorOutline /></ListItemIcon>
                    <ListItemText primary='No detections found' />
                  </ListItem>
                :
                  <ImageList gap={1} cols={4} className={classes.imageList}>
                    {detections.map((defect, i) => (
                      <ImageListItem key={defect._id}>
                        <Image media_id={defect.instance?.media_id} size='thumb' alt={defect._id} />
                      </ImageListItem>
                    ))}
                  </ImageList>
                }
              </div>
            </CardContent>
          </Card>
        </div>

        <div className={classes.cardWrapper}>
          <Card>
            <CardHeader 
              avatar={<Avatar><Description /></Avatar>}
              action={
                <>
                { latestDocument && (
                  <IconButton
                    className={classes.actionButtons}
                    disabled={inspectionIsLoading}
                    onClick={handleDownload}>
                    <GetApp />
                  </IconButton>
                )}
                <IconButton
                  style={{ padding: 0 }}
                  disabled={inspectionIsLoading}
                  href={`/documents?inspection=${inspection._id}`}
                >
                  <Launch />
                </IconButton>
                </>
              }
              title={'Latest Documents'}
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <CardContent>
              <List>
                { documents.length === 0 ?
                  <ListItem>
                    <ListItemIcon><ErrorOutline /></ListItemIcon>
                    <ListItemText primary='No documents found' />
                  </ListItem>
                  : documents.map((doc, i) => (
                    <ListItem key={i} style={{ cursor: 'pointer' }}
                      onClick={() => { history.push(`/document/${doc.document_id}`) }} >
                      <ListItemIcon><Description /></ListItemIcon>
                      <ListItemText
                        primary={doc.name}
                        secondary={doc.description}
                      />
                    </ListItem>
                  )) }
              </List>
              <Grid container spacing={2}>
                <Grid item>
                  <Button size='small' variant='outlined' color='primary'
                          disabled={inspectionIsLoading}
                          href={`/documents?inspection=${inspection._id}&upload=true`}>
                    Upload
                  </Button>
                </Grid>
                <Grid item>
                  <Button size='small' variant='outlined' color='primary'
                          disabled={inspectionIsLoading}
                    href={`/reports?inspection=${inspection._id}`}>
                    Reports
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
    { metaOpen &&
      <MetaDialog isUpdate={true}
        open={metaOpen}
        onClose={() => setMetaOpen(false)}
        inspection={metaData}
      />
    }
    { statusOpen &&
      <StatusDialog
        open={statusOpen}
        onClose={() => setStatusOpen(false)}
        inspection={metaData}
      />
    }
  </>
}

export default withStyles(styles)(InspectionSummary)
