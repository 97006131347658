import { useContext } from 'react'
import { Link }     from 'react-router-dom'
import { history }  from '../../utils/store'
import { allRoles } from '../../utils/enums'
import { getAccountsUrl } from '../../api/accounts'
import { AccountsContext } from '../AccountsContextProvider'
import {
  dd_mmm_yyyyEpoch,
} from '../../utils'
import Media from './Media'
import {
  Avatar,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  Apartment,
  AssignmentTurnedIn,
  AccountCircle,
  // BarChart,
  Description,
  ExitToApp,
  FormatListNumbered,
  ImageSearch,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  menuGrid: {
    height: '100%',
    width:  theme.spacing(33),
  },
  avatar: {
    margin: theme.spacing(1),
    width:  theme.spacing(7),
    height: theme.spacing(7),
    objectFit: 'contain',
  },
  listItem: {
    '&:hover': {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
      '&.MuiListItemIcon-root': {
        color:         theme.palette.common.white,
      },
    },
    '&.Mui-selected': {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root': {
        color:         theme.palette.common.white,
      },
      '&:hover': {
        color:           theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
})

function SideDrawer({ classes, open, activeMenu, onClick, onClose }) {
  const { user, company, subscriptions } = useContext(AccountsContext)

  const sub = subscriptions?.length > 0 ? subscriptions[0] : {}

  let subscriptionText = ''
  let thisDate = Date.now()
  if ((sub.end_date - thisDate) < (1000 * 3600 * 24 * 7) && (sub.end_date - thisDate) > 0) {
    subscriptionText = `Your subscription will expire on ${dd_mmm_yyyyEpoch(sub.end_date)}`
  } else if (thisDate < sub.start_date) {
    subscriptionText = `Your subscription will be active on ${dd_mmm_yyyyEpoch(sub.start_date)}`
  } else if ((sub.end_date - thisDate) < 0 ) {
    subscriptionText = `Your subscription is expired on ${dd_mmm_yyyyEpoch(sub.end_date)}`
  }
  const handleLogout = () => {
    let path = '/logout'
    history.push(path)
  }

  const topMenu = [
    { name: 'Facilities',  link: '/facilities',  icon: <Apartment /> },
    { name: 'Inspections', link: '/inspections', icon: <AssignmentTurnedIn /> },
    { name: 'Documents',   link: '/documents',   icon: <Description /> },
    { name: 'Tasks',       link: '/tasks',       icon: <FormatListNumbered /> },
    { name: 'Algorithms',  link: '/algorithms',  icon: <ImageSearch /> },
  ]

  const topMenuList = <List>
    {topMenu.map(item => (
      <ListItem button className={classes.listItem}
        key={item.link}
        component={Link}
        to={item.link}
        selected={activeMenu === item.link}
        onClick={() => onClick(item.link)}>
        <ListItemIcon color='inherit'>{item.icon}</ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
      </ListItem>
    ))}
  </List>

  const roles = user?.app_roles?.facilities?.map(role => {
    return allRoles[role]?.fullName || role || ''
  }).join(', ')

  return (
    <Drawer open={open} onClose={onClose}>
      <br />
      { user && company &&
        <Grid container style={{ width: '264px' }}>
          <Grid item>
            <Avatar className={classes.avatar}>
              { user.avatar ?
                <Media mediaId={user.avatar} size='thumb' alt={user.username}
                  fill />
              : user.name?.charAt(0) || <AccountCircle />
              }
            </Avatar>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='h6'>{user.name}</Typography>
            <Typography variant='body2'>{roles}</Typography>
            <Typography variant='body2'><b>{company.name}</b></Typography>
          </Grid>
        </Grid>
      }
      <br />
      <Grid container
        direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        className={classes.menuGrid}>
        <Grid item>
          { topMenuList }
        </Grid>
        <Grid item>
          <List>
            <ListItem>
              <ListItemText primary={subscriptionText} />
            </ListItem>
            <ListItem button onClick={() => window.open(getAccountsUrl(), '_blank')}>
              <ListItemIcon className={classes.listIcon}><AccountCircle /></ListItemIcon>
              <ListItemText primary='Accounts' />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon className={classes.listIcon}><ExitToApp /></ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default withStyles(styles)(SideDrawer)
