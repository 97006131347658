import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

import { Button } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import {
  FeatureGroup,
  MapContainer,
  useMap
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import '../../../../assets/css/leaflet.css'
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { GeomanControls } from 'react-leaflet-geoman-v2'
import * as turf from '@turf/turf'

import { useUpdateFacilityMutation } from '../../../../api/inspectionOps'

import { usePrevious } from '../../../../utils/hooks'
import { DEFAULT_MAP_CENTER } from '../../../../utils/site-maps'
import theme from '../../../../utils/site-theme'
import CommonLayers from '../../../shared/map/CommonLayers'

const styles = theme => ({
  mapContainer: {
    width:  '100%',
    // height: '100%',
  },
  // Due to how leaflet works, need to set directly on the map container,
  // not the parent div
  leafletMap: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
})

function ProfileMinimap({ facility, classes }) {
  const [ showToolbar, setShowToolbar ] = useState(false)

  const [ updateFacility, {
    isLoading: isUpdating,
    isError:   isErrorUpdate,
    error:     errorUpdate,
  } ] = useUpdateFacilityMutation()

  const handleSaveFacilityLocation = (geoJSON) => {
    const newFacility = {
      _id: facility._id,
      centroid: turf.centroid(turf.polygon(geoJSON.coordinates)).geometry,
      geometry: geoJSON,
    }

    updateFacility(newFacility)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('update failed', fulfilled)
        toast.error('Failed to update facility location.')
      } else {
        console.log('update success', fulfilled)
        console.log('update results: isUpdating', isUpdating, 'isErrorUpdate', isErrorUpdate)
        toast.success('Facility location updated.')
      }
    })
    .catch(rejected => {
      console.log('udpate rejected', rejected)
      console.log('update results: isUpdating', isUpdating, 'isErrorUpdate', isErrorUpdate, 'errorUpdate', errorUpdate)
      toast.error('Error updating facility location.')
    })
  }

  return <>
    <div className={classes.mapContainer}>
      <MapContainer
        className={classes.leafletMap}
        center={DEFAULT_MAP_CENTER}
        zoom={13}
        maxZoom={20}>
        <CommonLayers position='topright' defaultLayer='onemap'/>
        <GeomanTools isEditing={showToolbar} setShowToolbar={setShowToolbar} location={facility.location} onSave={handleSaveFacilityLocation}/>
        <FacilityMarker centroid={facility.centroid} geometry={facility.geometry}/>
      </MapContainer>
    </div>
    { isUpdating && 'Updating...' }
  </>
}

function GeomanTools({isEditing, setShowToolbar, location, onSave}) {
  const featureGroupRef = useRef(null)
  const prevLocation = usePrevious(location)

  const savePolygon = () => {
    const fgroup = featureGroupRef.current
    const geoJSON = fgroup.toGeoJSON()
    if (geoJSON.features?.length > 0) {
      onSave({...geoJSON.features[0].geometry})
      geoJSON.features = []
      fgroup.clearLayers()
    }
  }

  const clearPolygons = () => {
    const fgroup = featureGroupRef.current
    const geoJSON = fgroup.toGeoJSON()
    geoJSON.features = []
    onSave({...geoJSON})
    fgroup.clearLayers()
  }

  useEffect(()=>{
    // no choice need to use geometry compare, because no unique identifier for each feature
    let isNotEqual = 
      ( 
        location?.features?.length !== prevLocation?.features?.length ||
        !location?.features.every((f, i) => turf.booleanEqual(f, prevLocation?.features[i]))
      )

    if(isNotEqual) {
      let layers = featureGroupRef?.current?.getLayers()
      for(let i = 0; i < layers.length;i ++){
        featureGroupRef.current.removeLayer(layers[i])
      }
      
      L.geoJSON(location, {
        onEachFeature: (feature, layer) => {
          layer.options.pmIgnore = false
          L.PM.reInitLayer(layer)
          layer.bringToFront()
          featureGroupRef.current.addLayer(layer)
        }
      })
    }
  }, [prevLocation, location])
  
  return (
    <>
      <div style={{position: 'absolute', zIndex: 999, top: '72px', right: '10px'}}>
        { !isEditing ?
          <Button variant='contained' 
            style={{backgroundColor: '#ffffff', minWidth: '0px', padding: '8px'}}
            onClick={() => setShowToolbar(true)}>
              <Edit />
          </Button>
          : <>
            <Button variant='contained'
              style={{backgroundColor: '#ffffff', display: 'block'}}
              onClick={() => {
              setShowToolbar(false)
              savePolygon()}}>
                Close
            </Button>
            <Button variant='outlined'
              style={{backgroundColor: '#ffffff', display: 'block', marginTop: '4px'}}
              onClick={() => {
              setShowToolbar(false)
              clearPolygons()}}>
                Clear
            </Button>
          </>
        }
      </div>
      <FeatureGroup ref={featureGroupRef} style={{zIndex: 999}}>
        { isEditing ?
          <GeomanControls
            options={{
              position: 'topleft',
              drawMarker: false,
              drawCircle: false,
              drawRectangle: false,
              drawCircleMarker: false,
              drawText: false,
              editMode: false,
              dragMode: false,
              removalMode: false,
              rotateMode: false,
              cutPolygon: false
            }}
          />
          : null
        }
      </FeatureGroup>
    </>
  )
}

function FacilityMarker({ centroid, geometry }) {
  const map = useMap()

  useEffect(() => {
    if (centroid && centroid?.coordinates?.length >= 2) {
      // TODO replace with a real Building Marker
      //      OR, the shape of the building
      // TODO remove old marker before drawing new marker

      const circleOptions = {
        radius: 30,
        color:  theme.palette.secondary.main,
        weight: 2,
        fill:   true,
        fillColor: theme.palette.secondary.main,
      }

      L.circleMarker([centroid.coordinates[1],centroid.coordinates[0]], circleOptions).addTo(map)
      map.flyTo([centroid.coordinates[1],centroid.coordinates[0]], 15)
    }

    if (geometry) {
      const polygonOptions = {
        color:  theme.palette.primary.main,
        weight: 2,
        fill:   true,
        fillColor: theme.palette.primary.main,
      }

      if (geometry.type === 'Polygon') {
        L.polygon(geometry.coordinates[0].map(c => [c[1],c[0]]), polygonOptions).addTo(map)
        if (centroid && centroid?.coordinates?.length >= 2)
          map.flyTo([centroid.coordinates[1],centroid.coordinates[0]], 15)
      }
    }

  }, [centroid, geometry, map])
  

  return null
}

export default withStyles(styles)(ProfileMinimap)
