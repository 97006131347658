import { useEffect, useState } from 'react'
import {
  useUpdateInspectionStatusMutation,
} from '../../../../api/inspectionOps'
import {
  dd_mmm_yyyyEpoch
} from '../../../../utils'
import {
  allInspectionStatuses,
  validInspectionStatuses,
} from '../../../../utils/enums'
import {
  InspectionStatusIcon,
} from '../../../../components/shared/Icons'
import User from '../../../../components/shared/text/User'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'
import TopXCloseButton from '../../../shared/TopXCloseButton'

const styles = theme => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  selectField: {
    '& .MuiInputBase-input': {
      display: 'flex',
    },
  },
})

const StatusDialog = ({
  classes,
  open,
  onClose,
  inspection,
}) => {

  // Local state for edits
  const [_inspection, setInspection] = useState(inspection || {status: 'draft'})

  const [ updateInspectionStatus, updateStatusResult ] = useUpdateInspectionStatusMutation()

  const [ isLoading, setIsLoading ] = useState(false)
  useEffect(() => {
    if (inspection) {
      // console.log('Populating inspection fields', inspection)
      setInspection(inspection)
    }
  }, [inspection])

  const handleInputChange = (event) => {
    const name = event.target.name
    let value = event.target.value

    switch (name) {
      case 'facilities':
        value = [value]
        break
      default:
        break
    }
    setInspection(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleUpdateInspection() {
    
    if (inspection.status !== _inspection.status) {
        setIsLoading(true)
        const patchBody = {
            _id: _inspection._id,
            status: _inspection.status,
        }

        updateInspectionStatus(patchBody)
        .unwrap()
        .then(fulfilled => {
            console.log('update status fulfilled', fulfilled)
            console.log('update status result', updateStatusResult)
            setIsLoading(false)
            onClose()
        })
        .catch(rejected => {
            console.log('update status rejected', rejected)
            toast.error('Unable to update inspection status. Try again later.')
            setIsLoading(false)
        })
    }
  }

  const statusSelectField = (
    <TextField select fullWidth
      label='Status'
      name='status'
      value={_inspection.status}
      className={classes.selectField}
      variant='outlined'
      onChange={handleInputChange}>
      { validInspectionStatuses.map(status =>
        <MenuItem key={status} value={status}>
          <ListItemIcon>
            <InspectionStatusIcon type={status} />
          </ListItemIcon>
          <ListItemText primary={allInspectionStatuses[status]?.label || 'Unknown inspection status' } />
        </MenuItem>
      )}
    </TextField>
  )

  const cancelReasonTextField = (
    <TextField multiline fullWidth
      label='Reason inspection was cancelled'
      value={_inspection.cancel_reason}
      variant='outlined'
      minRows={2}
      onChange={handleInputChange} />
  )

  return (<>
    <Dialog open={open} onClose={onClose} scroll='paper' fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        {'Change Status'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
            <Grid item md={12}>
                { statusSelectField }
                { _inspection.status === 'cancelled' ? cancelReasonTextField : null }
            </Grid> 
        </Grid>
        <br />
        <OtherDetails inspection={_inspection} />
      </DialogContent>
      <DialogActions >
          <Button variant='contained' color='primary'
            onClick={handleUpdateInspection} 
            disabled={isLoading} >
            {isLoading ? (
            <>
              <CircularProgress size={20} className={classes.spinnerIcon} />
              Loading...
            </>
          ) : (
            'Change'
          )}
          </Button>
      </DialogActions>
    </Dialog>
  </>)
}

function OtherDetails({ inspection }) {
  return (<>
    <Divider />
    <Grid container>
      <Grid item xs={3}>
        <Typography variant='overline'>Created By</Typography>
        <Typography variant='body1'>
          {inspection.created_by ? User({ id: inspection.created_by, name: true }) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>Created On</Typography>
        <Typography variant='body1'>
          {inspection.created_on ? dd_mmm_yyyyEpoch(inspection.created_on) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>Last Modified By</Typography>
        <Typography variant='body1'>
          {inspection.last_modified_by ? User({ id: inspection.last_modified_by, name: true }) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>Last Modified On</Typography>
        <Typography variant='body1'>
          {inspection.last_modified_on ? dd_mmm_yyyyEpoch(inspection.last_modified_on) : '-'}
        </Typography>
      </Grid>
    </Grid>
  </>)
}

export default withStyles(styles)(StatusDialog)
